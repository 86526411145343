<template>
  <div>
    <!-- 页头搜索 -->
    <div class="input">
      <div class="input-font" v-if="userRole">
        <div class="fontdiv">商户号</div>
        <el-input placeholder="请输入商户号" v-model="searchForm.mchNo" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">应用ID</div>
        <el-input placeholder="请输入应用ID" v-model="searchForm.appId" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">二维码描述</div>
        <el-input placeholder="请输入二维码描述" v-model="searchForm.productMsg" clearable size="medium"></el-input>
      </div>
      <div class="input-font">
        <div class="fontdiv">静态码状态</div>
        <el-select v-model="searchForm.state" placeholder="请选择状态" clearable class="select-width" size="medium">
          <el-option label="停用" :value="0"/>
          <el-option label="启用" :value="1"/>
        </el-select>
      </div>
      <el-button class="search-button button-height" type="primary" icon="el-icon-search" :loading="false"
                 @click="getTableData()">搜索
      </el-button>
      <el-button class="button-height" plain icon="el-icon-refresh" @click="resetSearch()">重置</el-button>
    </div>

    <!-- 页头按钮 -->
    <el-row>
      <el-button size="medium" type="success" plain icon="el-icon-download" :disabled="false" @click="add()">静态码创建
      </el-button>
      <el-button size="medium" type="danger" plain icon="el-icon-download" :disabled="false"
                 @click="deleteList()">批量删除
      </el-button>
    </el-row>

    <!-- 列表渲染 -->
    <div class="table">
      <el-table :data="tableData" ref="multipleTable" tooltip-effect="dark"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" min-width="60"></el-table-column>
        <el-table-column prop="productMsg" label="静态码描述" min-width="160">
          <template #default="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.productMsg" placement="top">
              <div class="text-omit">{{ scope.row.productMsg }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="mchNo" label="商户号" min-width="170"></el-table-column>
        <el-table-column prop="mchAppId" label="应用ID" min-width="170"></el-table-column>
        <el-table-column prop="notifyUrl" label="回调地址" min-width="190">
          <template #default="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.notifyUrl" placement="top">
              <div class="text-omit">{{ scope.row.notifyUrl }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="状态" min-width="90">
          <template #default="scope">

            <div @click="changeStaticCodeStatus(scope.row)">
              <el-switch v-model="scope.row.state" :active-value="1" :inactive-value="0"></el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="expiredTime" label="过期时间" min-width="160"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="160"></el-table-column>

        <el-table-column prop="updateTime" label="更新时间" min-width="160"></el-table-column>
        <!-- 浮动侧边栏 -->
        <el-table-column label="操作" min-width="160" fixed="right" align="center">
          <template #default="scope">
            <el-link class="marginRight" @click="previewRow(scope.row)" type="primary"
                     :underline="false">预览
            </el-link>
            <el-link class="marginRight" @click="editRow(scope.row)" type="primary" :underline="false">编辑
            </el-link>
            <el-link @click="deleteRow(scope.row.id)" type="danger" :underline="false">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <Pagination :total="total" :page="pageNumber" :size="pageSize" @getPage="getPage($event)"
                @getSize="getSize($event)">
    </Pagination>

    <!-- 添加 -->
    <el-drawer title="添加" :visible.sync="addFormVisible" direction="rtl" :before-close="handleClose" size="35%">
      <div class="drawer-content">
        <el-form :model="addForm" :rules="rules" ref="addForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="商户号" prop="mchNo">
            <el-input v-model="addForm.mchNo" placeholder="请输入商户号"></el-input>
          </el-form-item>
          <el-form-item label="应用ID" prop="mchAppId">
            <el-input v-model="addForm.mchAppId" placeholder="请输入应用ID"></el-input>
          </el-form-item>
          <el-form-item label="支付通知地址" prop="mchAppId">
            <el-input v-model="addForm.notifyUrl" placeholder="请输入支付通知地址"></el-input>
          </el-form-item>
          <el-form-item label="静态码描述" prop="qrCodeDetail">
            <el-input v-model="addForm.qrCodeDetail" placeholder="请输入静态码描述"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <a-button class="marginRight" @click="addFormCancel">
            取消
          </a-button>
          <a-button class="marginRight" @click="resetForm">
            重置
          </a-button>
          <a-button type="primary" @click="addSubmit">
            确认
          </a-button>
        </div>
      </div>
    </el-drawer>

    <!-- 修改     -->
    <el-drawer title="修改" :visible.sync="updateFormVisible" direction="rtl" :before-close="handleClose" size="35%">
      <div class="drawer-content">
        <el-form :model="updateForm" :rules="rules" ref="updateForm" label-width="110px" class="demo-ruleForm">
          <el-form-item label="支付通知地址" prop="mchAppId">
            <el-input v-model="updateForm.notifyUrl" placeholder="请输入支付通知地址"></el-input>
          </el-form-item>
          <el-form-item label="静态码描述" prop="qrCodeDetail">
            <el-input v-model="updateForm.qrCodeDetail" placeholder="请输入静态码描述"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <a-button class="marginRight" @click="updateFormCancel">
            取消
          </a-button>
          <a-button class="marginRight" @click="resetForm">
            重置
          </a-button>
          <a-button type="primary" @click="updateSubmit">
            确认
          </a-button>
        </div>
      </div>
    </el-drawer>
    <!-- 预览二维码弹框 -->
    <el-dialog :visible.sync="previewQrCodeVisitable" width="280px" center :show-close="true" class="dialog"
               :close-on-press-escape="false" :close-on-click-modal="false" :before-close="previewCancel">
      <img :src="previewQrCodeSrc" class="qrcode-img">
    </el-dialog>

    <!-- 支付二维码弹框 -->
    <el-dialog :visible.sync="payQrCodeVisitable" width="280px" center :show-close="true" class="dialog"
               :close-on-press-escape="false" :close-on-click-modal="false" :before-close="payQrCodeCancel">
      <img :src="payQrCodeSrc" class="qrcode-img">
    </el-dialog>
  </div>
</template>

<script>
import {
  changeStatus,
  deleteStaticCodeInfo,
  getStaticCodeInfoData,
  getStaticQrCode,
  previewQrCode,
  update
} from "@/api/staticCodeManager/index";
import Pagination from "@/components/Pagination";

export default {
  name: "payWayInfo",
  components: {Pagination},
  data() {
    return {
      //权限
      userRole: false,
      //页头搜索数据
      searchForm: {},
      //保存多选框选中的行数据
      selectedRows: [],
      //渲染列表数据
      tableData: [],
      //总条数
      total: 0,
      //当前页数
      pageNumber: 1,
      //当前每页条数
      pageSize: 5,
      //添加 是否展示
      addFormVisible: false,
      updateFormVisible: false,
      //添加表单数据
      addForm: {},
      updateForm: {},
      //添加表单数据规则
      rules: {
        mchNo: [
          {required: true, message: '请输入商户号', trigger: 'blur'}
        ],
        mchAppId: [
          {required: true, message: '请输入应用Id', trigger: 'blur'}
        ],
        notifyUrl: [
          {required: true, message: '请输入支付通知地址', trigger: 'blur'}
        ],
        qrCodeDetail: [
          {required: true, message: '请输入静态码描述', trigger: 'blur'}
        ],
      },
      //支付二维码弹框
      payQrCodeVisitable: false,
      //支付二维码--临时二维码url
      payQrCodeSrc: '',
      //预览二维码弹框
      previewQrCodeVisitable: false,
      //预览二维码--url
      previewQrCodeSrc: '',
    };
  },

  mounted() {
    this.getTableData();
    this.getUserRole();
  },

  methods: {
    //分页查询
    async getTableData() {
      const data = {...this.searchForm, pageNum: this.pageNumber, pageSize: this.pageSize};
      const res = await getStaticCodeInfoData(data);
      console.log(res)
      this.tableData = res.data.rows
      this.total = parseInt(res.data.total)
    },

    //获取权限
    getUserRole() {
      const userRole = localStorage.getItem("userRole");
      console.log("sideNav页面：", userRole);
      if (userRole.includes("admin")) {
        this.userRole = true
      }
    },

    // 页头重置
    resetSearch() {
      this.searchForm = {}
    },

    //通过选中的多行数据存入数组
    handleSelectionChange(selection) {
      this.selectedRows = selection;
      console.log(this.selectedRows)
    },

    //表单重置（添加/修改）
    resetForm() {
      this.addForm = {}
      this.updateForm = {}
    },

    //批量删除按钮
    async deleteList() {
      this.$confirm('此操作将永久删除选中商户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = this.selectedRows.map((item) => {
          return item.id
        })
        console.log(data)
        const res = await deleteStaticCodeInfo(data);
        console.log(res)
        this.$message.success("删除成功");
        this.getTableData();
      }).catch((e) => {
        if (e == 'cancel') {
          this.$message.info("已取消删除");
        }
      });
    },

    //添加按钮
    add() {
      this.addFormVisible = true
    },

    //添加抽屉取消按钮
    addFormCancel() {
      this.addFormVisible = false
      this.addForm = {}
    },

    //添加抽屉提交按钮
    async addSubmit() {
      const res = await getStaticQrCode(this.addForm)
      if (res.size < 200) {
        this.$message.error("添加失败");
        return
      }
      this.addFormVisible = false
      const blob = new Blob([res], {type: 'image/png'});
      let src = window.URL.createObjectURL(blob);
      this.payQrCodeSrc = src;
      this.payQrCodeVisitable = true
      this.$message.success("添加成功");
    },

    //支付二维码对话框关闭回调
    payQrCodeCancel(e) {
      this.payQrCodeVisitable = false
      this.getTableData()
    },

    //预览对话框
    async previewRow(row) {
      this.previewQrCodeVisitable = true
      const res = await previewQrCode({"ossId": row.codeId})
      this.previewQrCodeSrc = res.data.url
    },

    //预览对话框关闭回调
    previewCancel() {
      this.previewQrCodeVisitable = false
    },

    // 删除单条
    deleteRow(id) {
      this.$confirm('此操作将永久删除该商户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = [id]
        const res = await deleteStaticCodeInfo(data);
        this.getTableData();
        this.$message.success("删除成功");
      }).catch(() => {
        if (e == 'cancel') {
          this.$message.info("已取消删除");
        }
      });
    },

    //抽屉关闭回调
    handleClose(done) {
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     done();
      //   })
      //   .catch(_ => { });
      done()
      this.addForm = {}
      this.updateForm = {}
      this.argumentForm = {}
    },

    //获取当前页数
    getPage(value) {
      this.pageNumber = value;
      this.getTableData();
    },

    //获取每页多少条
    getSize(value) {
      this.pageSize = value;
      this.getTableData();
    },

    //修改状态
    async changeStaticCodeStatus(row) {
      var text = ""
      if (row.state === 0) {
        text = "禁用成功"
      } else if (row.state == 1) {
        text = "启用成功"
      }
      await changeStatus(row.id, row.state)
      this.$message.success(text);
    },
    //修改抽屉保存按钮
    async updateSubmit() {
      const res = await update(this.updateForm)
      this.$message.success("修改成功");
      this.updateFormVisible = false
    },
    //修改抽屉取消按钮
    updateFormCancel() {
      this.updateFormVisible = false
      this.updateForm = {}
    },
    //修改
    editRow(row) {
      this.updateForm = row
      this.updateFormVisible = true
    },

  }
}
</script>

<style scoped lang="less">
/* 搜索框样式 */
.input {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;

  .input-font {
    width: 400px;
    display: flex;
    margin-bottom: 10px;

    .fontdiv {
      color: #999;
      font-weight: 600;
      font-size: 15px;
      width: 150px;
      line-height: 36px;
      text-align: center;
    }

    .select-width {
      width: 400px;
    }
  }

  .button-height {
    height: 36px;
    margin-bottom: 10px;
    margin-left: 15px;
  }

}

/* 表格 */
.table {
  margin-top: 15px;

  .marginRight {
    margin-right: 20px;
  }

  :deep .el-table .el-table__header-wrapper .el-table__header .el-table__cell {
    font-size: 14px;
    color: #999;
    font-weight: 600px;
    text-align: center;
    background-color: #f2f2f2;
  }

  :deep .el-table th.el-table__cell.is-leaf {
    background-color: #f2f2f2 !important;
  }

  :deep .el-table .el-table__body-wrapper .el-table__body .el-table__row .el-table__cell .cell {
    text-overflow: clip;
    text-align: center;
  }
}

/* 抽屉内容 */
.drawer-content {
  padding: 0 30px 70px 20px;

  .drawer-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;
    text-align: right;
    z-index: 1;
  }

  .marginRight {
    margin-right: 8px;
  }
}

/* 文字超出省略 */
.text-omit {
  min-width: 150px;
  /* 设置容器宽度 */
  white-space: nowrap;
  /* 防止文本换行 */
  overflow: hidden;
  /* 文本溢出容器时隐藏 */
  text-overflow: ellipsis;
  /* 使用省略号表示被隐藏的文本 */
}

// 二维码弹框
.dialog {
  :deep .el-dialog--center .el-dialog__body {
    padding: 5px;
  }

  :deep .el-dialog__header {
    padding: 15px;
  }

  .qrcode-img {
    width: 270px;
    height: 270px;
  }
}
</style>