import service from "@/utils/request";

//分页查询所有
export const getStaticCodeInfoData = (params) =>
    service({
        url: "/staticCode/queryPage",
        method: "get",
        params: params,
    });

//静态码支付码获取
export const getStaticQrCode = (data) =>
    service({
        url: "/payExperience/getStaticCode",
        method: "post",
        data: data,
        responseType: "blob",
    });

//预览
export const previewQrCode = (params) =>
    service({
        url: "/oss/getById",
        method: "get",
        params: params,
    });
//修改
export const update = (data) =>
    service({
        url: "/staticCode/update",
        method: "post",
        data: data,
    });

//删除
export const deleteStaticCodeInfo = (data) =>
    service({
        url: "/staticCode/deleteByIds",
        method: "post",
        data: data,
    });

//状态修改
export const changeStatus = (id, state) => service({
    url: '/staticCode/change/' + id + "/" + state,
    method: "get",
});


